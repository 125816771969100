import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  list: null,
  pyramid: null,
  levelOptions: null,
  listedLevels: null,
  tabIndex: 0,
  formOpen: false
};

const slice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedEntity(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    // GET MANAGE ENTITIES
    getEntityListSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    setTabIndex(state, action) {
      state.isLoading = false;
      state.tabIndex = action.payload;
    },

    setLevelOptions(state, action) {
      state.isLoading = false;
      state.levelOptions = action.payload;
    },

    setListedLevels(state, action) {
      state.isLoading = false;
      state.listedLevels = action.payload;
    },

    getPyramidSuccess(state, action) {
      state.isLoading = false;
      state.pyramid = action.payload;
    },

    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, setSelectedEntity, setListedLevels, setLevelOptions, setTabIndex } = slice.actions;

// ----------------------------------------------------------------------

export function getEntity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/entities/edit/${id}`);
      dispatch(slice.actions.setSelectedEntity(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEntity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/entities/${id}`);
  };
}

export function createEntityList() {
  return async () => {
    try {
      const response = await axios.get(`/api/entities/download`);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}

export function getEntities(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/entities', {
        params: {
          page: params?.page || 1,
          stage: params?.stageId || '',
          parent: params?.parentId || '',
          paging: params.paging === undefined ? true : params.paging,
          with_data: params.withData === undefined ? false : params.withData,
          with_user_entities: params.withUserEntities === undefined ? false : params.withUserEntities,
          per_page: params?.perPage || 10,
          'ids[]': params?.entityId || [],
          groups: params !== undefined && params.withGroups !== undefined ? params.withGroups : [],
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getEntityListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateEntity(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/entities', { entity: params });
      } else {
        await axios.put(`/api/entities/${params.id}`, { entity: params });
      }
      dispatch(getEntities({ stageId: params.stage_id }));
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPyramid(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/entities/pyramid', params);
      dispatch(slice.actions.getPyramidSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
